<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Career" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs4" v-if="tabValue === 0">
                <!-- <va-input
                  label="Name field *"
                  v-model="nameField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_name"
                  :error-messages="errors.formcareer_name"
                  @input="delete errors.formcareer_name"
                />
                <va-input
                  label="Email field *"
                  v-model="emailField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_email"
                  :error-messages="errors.formcareer_email"
                  @input="delete errors.formcareer_email"
                />
                <va-input
                  label="Phone field *"
                  v-model="phoneField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_phone"
                  :error-messages="errors.formcareer_phone"
                  @input="delete errors.formcareer_phone"
                />
                <va-input
                  label="Career objective field *"
                  v-model="objectiveField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_career_objective"
                  :error-messages="errors.formcareer_career_objective"
                  @input="delete errors.formcareer_career_objective"
                />
                <va-input
                  label="City field *"
                  v-model="cityField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_city"
                  :error-messages="errors.formcareer_city"
                  @input="delete errors.formcareer_city"
                />
                <va-input
                  label="Additional information field *"
                  v-model="additionalField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_additional_information"
                  :error-messages="errors.formcareer_additional_information"
                  @input="delete errors.formcareer_additional_information"
                />
                <va-input
                  label="Without resume field *"
                  v-model="withoutResumeField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_without_resume"
                  :error-messages="errors.formcareer_without_resume"
                  @input="delete errors.formcareer_without_resume"
                />
                <va-input
                  label="File field *"
                  v-model="fileField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_file"
                  :error-messages="errors.formcareer_file"
                  @input="delete errors.formcareer_file"
                />
                <va-input
                  label="File message *"
                  v-model="fileMessage"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_file_message"
                  :error-messages="errors.formcareer_file_message"
                  @input="delete errors.formcareer_file_message"
                />
                <va-input
                  label="Submit button *"
                  v-model="submitButton"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formcareer_submit"
                  :error-messages="errors.formcareer_submit"
                  @input="delete errors.formcareer_submit"
                /> -->
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <!-- <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Success title *"
                  v-model="successTitle"
                  :messages="['The recommended number of characters is 90']"
                  :error="!!errors.formcareer_success_title"
                  :error-messages="errors.formcareer_success_title"
                  @input="delete errors.formcareer_success_title"
                />
                <va-input
                  label="Success message *"
                  v-model="successMessage"
                  type="textarea"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.formcareer_success_message"
                  :error-messages="errors.formcareer_success_message"
                  @input="delete errors.formcareer_success_message"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div> -->
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
    //   nameField: '',
    //   emailField: '',
    //   phoneField: '',
    //   objectiveField: '',
    //   cityField: '',
    //   additionalField: '',
    //   withoutResumeField: '',
    //   fileField: '',
    //   fileMessage: '',
    //   submitButton: '',

      //   successTitle: '',
      //   successMessage: '',
      id: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        formcareer_name: this.nameField,
        formcareer_email: this.emailField,
        formcareer_phone: this.phoneField,
        formcareer_career_objective: this.objectiveField,
        formcareer_city: this.cityField,
        formcareer_additional_information: this.additionalField,
        formcareer_without_resume: this.withoutResumeField,
        formcareer_file: this.fileField,
        formcareer_file_message: this.fileMessage,
        formcareer_submit: this.submitButton,

        formcareer_success_title: this.successTitle,
        formcareer_success_message: this.successMessage,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.formcareer_name || !!this.errors.formcareer_email || !!this.errors.formcareer_phone ||
          !!this.errors.formcareer_career_objective || !!this.errors.formcareer_city || !!this.errors.formcareer_additional_information ||
          !!this.errors.formcareer_without_resume || !!this.errors.formcareer_file || !!this.errors.formcareer_file_message || !!this.errors.formcareer_submit
      } else if (tab === 1) {
        return !!this.errors.formcareer_success_title || !!this.errors.formcareer_success_message
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/forms/${this.id}`, {
        main: {

        },
      })
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
    //   axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/pages/validate/7`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
    //     .then(response => {
    //       this.errors = response.data
    //       if (!Object.keys(this.errors).length) {
    //         this.submit()
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       this.showToast('Validate Fetch Error')
    //     })
      this.submit()
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/forms`)
        .then(response => {
          console.log(response.data)
          //   this.nameField = response.data.formcareer_name
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
